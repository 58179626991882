.hiThere,
.imAProduct {
  margin: 0;
  position: relative;
}
.hiThere {
  font-size: var(--h1-size);
  line-height: 5.5rem;
  font-weight: 100;
  font-family: inherit;
}
.imAProduct {
  align-self: stretch;
  line-height: 1.44rem;
  font-weight: 300;
}
.mantasmantasmilkacom1 {
  text-decoration: underline;
}
.mantasmantasmilkacom {
  color: inherit;
}
.ifYoureCuriousContainer {
  margin: 0;
  align-self: stretch;
  position: relative;
  line-height: 1.44rem;
  font-weight: 300;
  color: var(--font-inherit);
}
.paragraph {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-xs);
}
.companies1 {
  margin: 0;
  align-self: stretch;
  position: relative;
  font-size: inherit;
  line-height: 2.5rem;
  font-weight: 100;
  font-family: inherit;
}
.logoul {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
}
.companies {
  background: linear-gradient(180deg, #f6f6f6, #fff);
  display: flex;
  flex-direction: column;
  padding: 2rem 3.5rem;
  align-items: center;
  justify-content: flex-start;
  gap: 2rem;
  text-align: center;
  font-size: var(--h1-size);
  flex: 1;
  box-sizing: border-box;
  max-width: 50%;
}
.sectionHi,
.wrapper {
  align-self: stretch;
  display: flex;
}
.wrapper {
  flex-direction: row;
  align-items: flex-end;
  justify-content: center;
  gap: var(--gap-lg);
  max-width: 60rem;
  width: 100%;
  margin: 0 auto;
}
.sectionHi {
  flex-direction: column;
  padding: var(--padding-4xl) 0;
  align-items: center;
  justify-content: flex-start;
}
.content,
.wrapper1 {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-xs);
}
.wrapper1 {
  gap: var(--gap-2xl);
  max-width: 60rem;
  width: 100%;
  margin: 0 auto;
}
.sectionProjects {
  align-self: stretch;
  background-color: #f6f6f6;
  padding: var(--padding-4xl) 0;
  font-size: var(--h1-size);
}
.home,
.main,
.sectionProjects {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.main {
  align-self: stretch;
  text-align: left;
  font-size: var(--paragraph-size);
  color: var(--color-black);
  font-family: var(--desktop-caption);
}
.home {
  position: relative;
  background-color: var(--color-white);
  width: 100%;
  overflow: hidden;
}
@media screen and (max-width: 1200px) {
  .paragraph {
    flex: unset;
    align-self: stretch;
  }
  .wrapper {
    flex-direction: column;
    gap: var(--gap-lg);
    align-items: flex-end;
    justify-content: flex-start;
    max-width: 60rem;
    width: 100%;
    margin: 0 auto;
  }
  .companies {
    max-width: 100%;
    gap: var(--gap-lg);
  }
}
@media screen and (max-width: 960px) {
  .paragraph {
    flex: unset;
    align-self: stretch;
  }
  .companies {
    align-items: center;
    justify-content: center;
    padding-left: 0;
    padding-top: var(--padding-sm);
    padding-right: 0;
    width: 100%;
    max-width: 100%;
  }
  .companies,
  .sectionHi,
  .wrapper {
    box-sizing: border-box;
  }
  .wrapper {
    flex-direction: column;
    gap: var(--gap-2xl);
    align-items: center;
    justify-content: flex-start;
    padding-left: 0;
    padding-top: 0;
    padding-right: 0;
    max-width: 37.25rem;
  }
  .sectionHi {
    padding: var(--padding-2xl) 0;
  }
  .main {
    gap: var(--gap-0);
    padding-left: 0;
    padding-top: 0;
    padding-right: 0;
    box-sizing: border-box;
  }
}
@media screen and (max-width: 420px) {
  .sectionHi {
    padding-left: 0;
  }
  .main {
    gap: var(--gap-0);
    padding-left: 0;
    box-sizing: border-box;
  }
}
