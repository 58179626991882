.mobilemenuClosedChild,
.mobilemenuClosedItem {
  position: relative;
  background-color: var(--color-black);
  width: 1.5rem;
  height: 0.13rem;
}
.mobilemenuClosedItem {
  width: 2rem;
}
.mobilemenuClosed {
  display: flex;
  flex-direction: column;
  /* padding: var(--padding-xs) var(--padding-xxs); */
  align-items: center;
  justify-content: center;
  gap: var(--gap-xs);
  z-index: 2;
}
.mobilemenuOpenChild,
.mobilemenuOpenItem {
  position: relative;
  background-color: var(--color-black);
  width: 2rem;
  height: 0.13rem;
  transform: rotate(-45deg);
  transform-origin: 0 0;
}
.mobilemenuOpenItem {
  transform: rotate(-135deg);
  margin-top: -1.5rem;
}
.mobilemenuOpen {
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  flex-direction: column;
  /* padding: var(--padding-xs) var(--padding-xxs); */
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  z-index: 2;
}
.mobilemenu {
  flex-direction: row;
  align-items: center;
}
.mobilemenu {
  display: none;
  justify-content: flex-start;
  gap: var(--gap-xs);
}


@media screen and (max-width: 960px) {
    .mobilemenu {
    display: flex;
    }
    .mobilemenuClosed {
        display: flex;
        width: 2.5rem;
        height: 2.5rem;
    }
    .is-open .mobilemenuOpen{
      display: flex;
    }
    .is-open .mobilemenuClosed {
      display: none;
    }
    .mobilemenuOpen {
    display: none;
    }

}
