.caption,
.imgIcon {
  align-self: stretch;
  position: relative;
}
.imgIcon {
  flex: 1;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: cover;
}
.caption {
  line-height: 1.44rem;
}
.sectionFluidImage {
  width: 100%;
  /* height: 42.06rem; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  font-size: var(--caption-size);
  color: var(--gray-1);
  font-family: var(--desktop-caption);
}
