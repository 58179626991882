.imgLeftIcon {
  flex: 1;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: cover;
}
.caption,
.imgLeftIcon,
.wraper {
  align-self: stretch;
}
.wraper {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.caption {
  position: relative;
  line-height: 1.44rem;
}
.sectionFluidImage {
  width: 100%;
  /* height: 42.06rem; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  font-size: var(--caption-size);
  color: var(--gray-1);
  font-family: var(--desktop-caption);
}
@media screen and (max-width: 960px) {
  .imgLeftIcon {
    flex: unset;
    align-self: stretch;
  }
  .wraper {
    flex-direction: column;
    gap: var(--gap-0);
    align-items: center;
    justify-content: flex-start;
  }
}
