.flsConcept1 {
  align-self: stretch;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  height: 32rem;
  flex-shrink: 0;
  object-fit: cover;
}
.prototypePrepapredFor {
  align-self: stretch;
  position: relative;
  line-height: 1.44rem;
}
.sectionImage,
.wrapper {
  display: flex;
  align-items: flex-start;
}
.wrapper {
  flex: 1;
  flex-direction: column;
  justify-content: flex-end;
  gap: var(--gap-xs);
  max-width: 60rem;
  width: 100%;
  margin: 0 auto;
}
.sectionImage {
  background: linear-gradient(-77.68deg, #011327, #05264b);
  width: 90rem;
  flex-direction: row;
  padding: var(--padding-4xl) 0;
  box-sizing: border-box;
  justify-content: space-between;
  text-align: center;
  font-size: var(--caption-size);
  color: var(--color-white);
  font-family: var(--desktop-caption);
  width: 100%;
  margin: 0 auto;
}

@media screen and (max-width: 420px) {
  .sectionImage, .flsConcept1 {
    width: 150%;
    padding: var(--padding-4xl) var(--padding-md);
    object-fit: cover;
    height: unset;
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
  .sectionImage {
    transform: translateX(-50%);
    margin-left: 50%;
  }
}