.mantasMilka {
  position: relative;
}

.SocialNavInHeader {
  justify-content: end;
}

.SocialNavInHeader a img {
  transform: translateY(-1.5rem);
}

.logoWrap {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  max-width: 7.5rem;
  font-size: 1.25rem;
  font-weight: 300;
}

.logoWrap img {
  width: 188px;
  height: 24px;
}


.logoWrap a::after {
  display: none;
}

.header {
  flex-direction: row;
  align-items: center;
  align-self: stretch;
  background-color: var(--color-white);
  height: 5.5rem;
  display: flex;
  padding: var(--padding-lg) var(--padding-md);
  box-sizing: border-box;
  justify-content: space-between;
  text-align: left;
  font-size: var(--h2-size);
  color: var(--color-black);
  font-family: var(--desktop-caption);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background: white;
  z-index: 1;
}

@media screen and (max-width: 960px) {

  .SocialNavInHeader {
    display: none;
  }

  .logoWrap {
    width: auto;
    align-self: unset;
  }

  .logoWrap a {
    display: flex;
  }

  .links {
    display: none;
  }

  .mobilemenu {
    display: flex;
  }

  .header {
    height: auto;
    flex-direction: row;
    gap: var(--gap-0);
    align-items: center;
    justify-content: space-between;
  }
}

@media screen and (max-width: 420px) {
  .links {
    display: none;
  }

  .mobilemenuClosed {
    display: flex;
  }

  .mobilemenuOpen {
    display: none;
  }

  .mobilemenu {
    display: flex;
  }

  .header {
    flex-direction: row;
    gap: var(--gap-0);
    align-items: center;
    justify-content: space-between;
    padding: var(--padding-sm) var(--padding-md);
    box-sizing: border-box;
  }
}