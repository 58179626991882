.nav {
  margin: 0;
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  max-width: 39.5rem;
  text-align: center;
  font-size: var(--h2-size);
  color: var(--color-black);
  font-family: var(--desktop-caption);
}
.nav a {
  font-size: var(--h4-size);
  font-weight: 300;
  text-decoration: none;
  cursor: pointer;
  color: inherit;
  flex: 1;
}
.nav a::after {
  display: none;
}
.nav.open {
  display: flex;
  position: absolute;
  height: 100vh;
  TOP: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: white;
  max-width: unset;
  z-index: 1;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  gap: 2rem;
}
.nav.open a {
  max-height: 2rem;
}
.FooterLinks {
  display: none;
}
@media screen and (max-width: 960px) {
    .nav {
      flex: 1;
    }
    .links,
    .nav {
      display: none;
    }
  }
  @media screen and (max-width: 420px) {
    .links,
    .nav {
      display: none;
    }
  }
  