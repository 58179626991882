@media screen and (max-width: 1200px) {

}
@media screen and (max-width: 960px) {
  .wrapper {
    align-self: stretch;
    width: 100%;
    margin: 0 auto;
    align-items: flex-start;
    justify-content: flex-start;
    max-width: 37.25rem;
  }

  h1, h2 {
    margin-top: var(--size-xs);
  }
}
@media screen and (max-width: 420px) {
  .wrapper {
    padding-left: var(--padding-md);
    padding-right: var(--padding-md);
    box-sizing: border-box;
    max-width: 24rem;
    width: 100%;
    margin: 0 auto;
  }
  h1, h2 {
    margin-top: var(--size-md);
  }
}
