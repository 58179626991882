.sectionGradient,
.wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.wrapper {
  flex: 1;
  align-items: flex-end;
  max-width: 60rem;
  width: 100%;
  margin: 0 auto;
}
.sectionGradient {
  width: 100%;
  padding: var(--padding-4xl) 0;
  box-sizing: border-box;
  align-items: flex-start;
  text-align: left;
  font-size: var(--h1-size);
  color: var(--color-white);
  font-family: var(--desktop-caption);
}
h3 {
  font-weight: 100;
}
.sectionGradient.gs {
  background: linear-gradient(-77.68deg, #011327, #05264b);
}

.sectionGradient.vinted {
  background: linear-gradient(302deg, #004654 27.45%, #007822 106.14%);
}

@media screen and (max-width: 960px) {
  .wrapper {
    max-width: 37.25rem;
  }
}
@media screen and (max-width: 420px) {
  .wrapper {

    margin: 0 auto;
    padding-left: var(--padding-md);
    padding-right: var(--padding-md);
    width: auto;
  }
  .sectionGradient {
    padding: var(--padding-2xl) 0;
  }
  .wrapper h3 {
    font-weight: 100;
    line-height: calc(var(--h3-size) * 1.5);
  }
}
