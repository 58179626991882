
.content.inside {
  gap: var(--gap-xs);
}
.content,
.cta {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.cta {
  cursor: pointer;
  border: 0;
  padding: var(--padding-sm) var(--padding-md);
  background-color: var(--color-black);
  flex-direction: row;
  text-decoration: none;
  transition: all 0.420s ease-in-out;
  color: var(--color-white);
}
.cta h4 {
  color: var(--color-white);
}
.cta:hover {
  background-color: #1f1f1f;
}
.content {
  flex: 1;
  flex-direction: column;
  gap: var(--gap-xl);
}
.imgIcon {
  flex: 1;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  height: 27.5rem;
  object-fit: cover;
}
.projectLivestats {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-2xl);
  text-align: left;
  font-size: var(--paragraph-size);
  color: var(--color-black);
  font-family: var(--desktop-caption);
}
@media screen and (max-width: 960px) {
  .projectLivestats {
    flex-direction: column-reverse;
    gap: var(--gap-xl);
  }
  .imgIcon {
    max-height: 20rem;
    width: 100%;
  }
}
@media screen and (max-width: 420px) {
  .content,
  .imgIcon {
    flex: unset;
    align-self: stretch;
  }
  .content {
    gap: var(--gap-lg);
  }
  .imgIcon {
    height: 15rem;
  }
  .projectLivestats {
    flex-direction: column-reverse;
    gap: var(--gap-sm);
  }
}
