.SocialNav {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: var(--gap-lg);
  max-width: fit-content;
}

.SocialNav a::after {
  display: none;
}

.SocialNav li {
  display: block;
}

.SocialNav a {
  display: block;
  width: var(--size-md);
  height: var(--size-md);
}

.SocialNav img {
  height: var(--size-md);
  width: var(--size-md);
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: cover;
}
.links a::after {
  display: none;
}
.Footer .links {
  padding-inline-start: 0;
}
.SocialNav img {
  width: var(--size-md);
}

@media screen and (max-width: 960px) {
  .links {
    display: none;
  }
}
@media screen and (max-width: 420px) {
  .links {
    display: none;
  }
}
