.section,
.wrapper {
  align-self: stretch;
  display: flex;
  box-sizing: border-box;
}
.section {
  flex-direction: column;
  padding: var(--padding-4xl) 0;
  align-items: center;
  justify-content: flex-start;
  background-size: cover;
  background-repeat: no-repeat;
  background-position-x: 0;
  background-position-y: 50%;
  font-family: var(--desktop-caption);
}
.wrapper {
  align-items: flex-start;
  justify-content: center;
  gap: var(--gap-2xl);
  max-width: 60rem;
  width: 100%;
  margin: 0 auto;
  flex: 1;
  padding: 0 var(--padding-sm);
  box-sizing: border-box;
  font-family: --var(--desktop-caption);
}

.row {
  flex-direction: row;
}

.column {
  flex-direction: column;
}

.fluid {
  /* Your styles for fluid sections */
}

@media screen and (max-width: 1200px) {
  .section {
    background-position-x: 0%;
  }
  .content {
    flex-direction: column;
  }
  .wrapper {
    max-width: 50rem;
  }
}

@media screen and (max-width: 960px) {
  .section {
    padding: var(--padding-3xl) 0;
    background-position-x: 30%;
  }
  .wrapper {
    max-width: 42rem;
    padding: 0 3rem;
    flex-direction: column;
    gap: var(--gap-lg);
  }
  .content {
    flex-direction: column;
    gap: var(--gap-sm);
  }
}

@media screen and (max-width: 640px) {
  .wrapper {
    max-width: 34rem;
  }
  .section {
    background-position-x: 60%;
  }
}
@media screen and (max-width: 420px) {
  .section {
    padding: var(--padding-2xl) 0;
    background-position-x: 55%;
  }
  .content {
    flex-direction: column;
  }
  .wrapper {
    max-width: 22rem;
    padding: 0 2rem;
    flex-direction: column;
    gap: var(--gap-3xl);
  }
}
@media screen and (max-width: 320px) {
  .wrapper {
    max-width: 18rem;
  }
  .section {
    background-position-x: 60%;
  }
}