.logoChild {
  position: relative;
  width: 100%;
  height: 100%;
  object-fit: cover;
  min-width: 100%;
  min-height: 100%;
  background-blend-mode: multiply;
}
.logo {
  position: relative;
  /* background-color: var(--color-white); */
  display: flex;
  flex-direction: column;
  padding: var(--padding-xs);
  align-items: center;
  justify-content: center;
  max-width: calc(50% - 2.5rem);
}
@media screen and (max-width: 1200px) {
  .content {
    flex-direction: row;
  }
}
@media screen and (max-width: 920px) {}

@media screen and (max-width: 640px) {}

@media screen and (max-width: 420px) {
  .logo {
    max-width: 60%;
  }
}