
.mantasmantasmilkacom {
  color: inherit;
}
.wrapper {
  align-items: flex-end;
}
.paragraph {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-xs);
}
.logoul {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
  gap: 2rem;
}
.companies {
  background: linear-gradient(180deg, #f6f6f6, #fff);
  display: flex;
  flex-direction: column;
  padding: 2rem 3.5rem;
  align-items: center;
  justify-content: flex-start;
  gap: 2rem;
  text-align: center;
  font-size: var(--h1-size);
  flex: 1;
  box-sizing: border-box;
  max-width: 50%;
  margin-top: auto;
}
.heading {
  align-self: stretch;
  position: relative;
  line-height: 5.5rem;
  font-weight: 100;
  flex: 1;
}
.content {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 4rem;
}
@media screen and (max-width: 1200px) {
  .content {
    flex-direction: row;
  }
  .paragraph {
    flex: unset;
    align-self: stretch;
    flex: 1
  }
  .wrapper {
    flex-direction: column;
    gap: var(--gap-lg);
    align-items: flex-end;
    justify-content: flex-start;
    max-width: 60rem;
    width: 100%;
    margin: 0 auto;
  }
  .companies {
    gap: var(--gap-lg);
    margin-top: auto;
  }
}
@media screen and (max-width: 960px) {
  .content {
    flex-direction: column;
    align-items: center;
  }
  .paragraph {
    align-self: stretch;
  }
  .companies {
    align-items: center;
    justify-content: center;
    padding-left: 4rem;
    padding-top: 4rem;
    padding-right: 4rem;
    width: 100%;
    max-width: 80%;
  }
  .companies,
  .wrapper {
    box-sizing: border-box;
  }
  .wrapper {
    flex-direction: column;
    gap: var(--gap-2xl);
    align-items: center;
    justify-content: flex-start;
    padding-left: 0;
    padding-top: 0;
    padding-right: 0;
    max-width: 37.25rem;
  }
}
@media screen and (max-width: 640px) {
  .companies {
    max-width: 100%;
    padding: var(--gap-xl) var(--padding-lg);
  }
  .logoul {
    flex-direction: column;
    align-items: center;
  }
}
@media screen and (max-width: 420px) {
  .paragraph {
    flex: unset;
    align-self: stretch;
  }
  .companies {
    gap: var(--gap-lg);
    align-items: flex-start;
    justify-content: flex-start;
    box-sizing: border-box;
    max-width: 100%;
    padding: var(--gap-lg) var(--padding-lg);
  }
  .wrapper {
    gap: var(--gap-2xl);
    box-sizing: border-box;
  }
  .wrapper {
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 0 var(--padding-sm) 0;
    max-width: 24.75rem;
  }
}
