/* Import Open Sans from Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@100;300;400;500;700&display=swap');

:root {
  /* fonts */
  --desktop-caption: "Open Sans";
  --font-inherit: inherit;
  /* Colors */
  --color-white: #ffffff;
  --color-black: #020202;
  --color-grey: #F6F6F6;
  --gray-1: #333;
  
  /* New size variables */
  --size-xxs: 0.25rem; /* 4px at default root font-size */
  --size-xs: 0.5rem;  /* 8px at default root font-size */
  --size-sm: 1rem;    /* 16px at default root font-size */
  --size-md: 1.5rem;  /* 24px at default root font-size */
  --size-lg: 2rem;    /* 32px at default root font-size */
  --size-xl: 3rem;    /* 48px at default root font-size */
  --size-2xl: 4rem;   /* 64px at default root font-size */
  --size-3xl: 6rem;   /* 96px at default root font-size */
  --size-4xl: 8rem;   /* 128px at default root font-size */
  --size-5xl: 10rem;  /* 160px at default root font-size */

  /* Gap variables using the size variables */
  --gap-xxs: var(--size-xxs);
  --gap-xs: var(--size-xs);
  --gap-sm: var(--size-sm);
  --gap-md: var(--size-md);
  --gap-lg: var(--size-lg);
  --gap-xl: var(--size-xl);
  --gap-2xl: var(--size-2xl);
  --gap-3xl: var(--size-3xl);
  --gap-4xl: var(--size-4xl);
  --gap-5xl: var(--size-5xl);
  /* Padding variables using the new size variables */
  --padding-xxs: var(--size-xxs);
  --padding-xs: var(--size-xs);
  --padding-sm: var(--size-sm);
  --padding-md: var(--size-md);
  --padding-lg: var(--size-lg);
  --padding-xl: var(--size-xl);
  --padding-2xl: var(--size-2xl);
  --padding-3xl: var(--size-3xl);
  --padding-4xl: var(--size-4xl);
  --padding-5xl: var(--size-5xl);
  /* font sizes */
  --h1-size: var(--size-3xl);
  --h2-size: var(--size-xl);
  --h3-size: var(--size-lg);
  --h4-size: 1.25rem;
  --h5-size: var(--size-sm);
  --caption-size: 0.75rem;
  --paragraph-size: var(--size-sm);
  --title-size: var(--size-xl);
}

body {
  font-family: var(--desktop-caption), sans-serif;
  margin: 0;
  line-height: normal;
}

h1, h2, h3, h4, h5, p {
  margin: 0;
}

/* ... (rest of the CSS) ... */

h1 {
  font-size: var(--h1-size);
  line-height: calc(var(--h1-size) * 1.2);
  font-weight: 300;
}

h2 {
  font-size: var(--h2-size);
  line-height: calc(var(--h2-size) * 1.2);
  font-weight: 300;
}

h3 {
  font-size: var(--h3-size);
  line-height: calc(var(--h3-size) * 1.25);
  font-weight: 300;
}

h4 {  
  font-size: var(--h4-size);
  line-height: calc(var(--h4-size) * 1.5);
  font-weight: 300;
}

p {
  font-size: var(--paragraph-size);
  line-height: calc(var(--paragraph-size) * 1.5);
  font-weight: 300;
  margin-top: 0.75rem;
}

h2 + p {
  margin-top: 0.5rem;
}

a {
  color: var(--color-black);
  cursor: pointer;
  text-decoration: none;
  position: relative;
}

a::after {
  content: " ";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 1px;
  background-color: var(--color-black);
  opacity: 0.64;
  transition: 0.42s ease-in-out;
}

a:hover::after {
  opacity: 0.92;
}

main ul {
  list-style-type: disc;
  padding-inline-start: 2rem;
  margin-top: 0;
}

main {
  padding-top: 5.5rem;
}

@media screen and (max-width: 1200px) {
  main {
    padding-top: 5.5rem;
  }
  :root {
    --h1-size: var(--size-2xl);
    --h2-size: var(--size-xl);
  }
}

@media screen and (max-width: 960px) {
  main {
    padding-top: 4.5rem;
  }
  :root {
    --h1-size: var(--size-2xl);
    --h2-size: var(--size-xl);
  }
}

@media screen and (max-width: 420px) {
  main {
    padding-top: 4.5rem;
    margin-top: 0;
  }
  :root {
    --h1-size: var(--size-xl);
    --h2-size: var(--size-lg);
    --h3-size: var(--size-md);
    --h4-size: 1.25rem;
  }
}
